.review {
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 460px;
}

.loading {
  height: 50px;
}

.clickCorrespondingVerbatimsWrap {
  position: relative;
  flex-grow: 1;
}

.clickCorrespondingVerbatims {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  border-radius: 4px;
  background: var(--primary-color);
  padding: 6px 10px;
  color: #fff;
  border: none;
  outline: 0;
  transition: 200ms;
  box-shadow: 1px 2px 1px 0 var(--primary-w70-color);
}

.horizontal {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 3fr 1fr;
  grid-auto-flow: column;
  height: 100%;
}


@media screen and (max-width: 1100px) {
  .review{
    width: 300px;
  }
}