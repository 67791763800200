.container {
  overflow: auto;
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  width: 100%;
  position: relative;
  transition: 200ms
}

// .container {
//   &:before {
//     content: '';
//     position: absolute;
//     display: block;
//     top: 0;
//     left: 0;
//     right: 0;
//     background: radial-gradient(farthest-side at 50% 0%, var(--primary-a30-color), var(--primary-a00-color)) 100% 0;
//     background-repeat: no-repeat;
//     background-size: 100% 5px;
//     height: 8px;
//     z-index: 1;
//   }
//   &:after {
//     content: '';
//     position: absolute;
//     display: block;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     background: radial-gradient(farthest-side at 50% 100%, var(--primary-a30-color), var(--primary-a00-color)) 0 100%;
//     background-repeat: no-repeat;
//     background-size: 100% 5px;
//     width: inherit;
//     height: 8px;
//     z-index: 1;
//   }
// }


.content {
  overflow: hidden;
  transition: 200ms
}

// .content {
//   &:before {
//     content: '';
//     position: absolute;
//     display: block;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 5px;
//     background: var(--primary-w90-color);
//     z-index: 2;
//   }
  
//   &:after {
//     position: relative;
//     content: '';
//     display: block;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 5px;
//     background: var(--primary-w90-color);
//     z-index: 2;
//     margin: 0 -1em -0.5em -1em;
//   }
// }