.section {
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  width: calc(50% - 10px);
  height: 600px;

  &:hover {
    .buttons {
      opacity: 1;
    }
  }
}

.auto.section {
  height: auto;
}

.container {
  border: 1px solid #f0f0f0;
  background: #fff;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  height: 100%;
}

.auto .container {
  height: auto;
}

.editingContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  transition: 200ms;
}

.editingContainer:hover {
  opacity: 1;
}

.full {
  width: calc(100% - 10px);
}

.header {
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  z-index: 100;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-shrink: 1;
  flex-grow: 0;
  background: #fff;
}

.title {
  line-height: 1.4em;
  margin: 0;
  padding: 14px 16px;
  font-size: 18px;
  font-weight: 550;
  letter-spacing: -0.04em;
  color: var(--text);
}

.filters {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.filters > * {
  border-left: 1px solid #f0f0f0;
}

.content {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 1;
}

.description {
  display: block;
  color: var(--descriptionColor);
  font-size: 14px;
  padding: 8px 16px;
}

.description strong {
  color: #404040;
  font-weight: 500;
}

.contentData {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  flex-direction: column;
  overflow: auto;
}

.auto .contentData {
  overflow: hidden;
  flex-basis: unset;
  flex-grow: unset;
}

.inset {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.delete {
  cursor: pointer;
}

.exportButton {
  position: absolute;
  bottom: 2px;
  right: 2px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #ebebeb;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  fill: #b0b0b0;
  transition: all 300ms;
  z-index: 1;
  font-size: 11px;
  transform: translate(25%, 25%);
}

.exportButton svg {
  width: auto;
  height: 50%;
}

.exportButton:hover {
  background: var(--primary-color);
  fill: #fff;
}

.highlight {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 10px;
  border: 2px dashed var(--primary-color);
  background: transparent;
  pointer-events: none;
  z-index: 0;
  opacity: 0;
  transition: all 300ms;
}

.exportButton:hover + .highlight {
  opacity: 1;
}




.buttons {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 2px;
  right: 2px;
  opacity: 0;
  transition: 200ms;
}