.withLeftNav {
  position: relative;
  margin-left: var(--nav-width);
  overflow: hidden;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  transition: 200ms;
  font-family: var(--main-font);

  background: hsl(240, 21%, 99%);

  &.hasDetail {
    margin-right: var(--detail-width);
  }
}

.withFilters {
  position: relative;
  margin-left: var(--filters-width);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  transition: 200ms;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
}


.spacer {
  flex-grow: 100000;
}

.filterContainer {
  display: flex;
  flex-direction: column;
  min-height: 0;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: var(--filters-width, 0);
  transition: 200ms;
}