.container {
  max-width: 1024px;
  position: relative;
  margin: 0 auto;
}

.section {
  max-width: 1024px;
  position: relative;

  .dragging {
    background: #fff;
  }
}

.title {
  margin: 0;
  padding: 0 0 0 8px;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: -0.015em;
  color: #40425e;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.description {
  max-width: 800px;
  margin: 0;
  padding: 1em 0.5em;
  font-size: 22px;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: #404040;
}

.reviews {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 10px;
}
.reviewsWithBorder {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 10px;
  padding-left: 15px;
  border-left: 7px solid #9b9b9b29;
  margin-left: 10px;
}

.draggableSection {
  user-select: none;
  position: relative;
}

.draggableReview {
  user-select: none;
  position: relative;
  height: 100%;

  &.dragging {
    background: transparent;
  }
}

.buttons {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  transform: translateX(-115%);
}
