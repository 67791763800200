.container {
  color: var(--text-color);
  position: relative;
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr max-content 1fr;
  grid-template-rows: 1fr max-content 1fr;
  grid-auto-columns: minmax(0, 20em);
  justify-content: center;
  justify-items: center;
  padding: 0 1em;
  box-sizing: border-box;
  min-height: 100vh;

  background: var(--primary-w90-color);
}

.content {
  width: 100%;
  padding: 1em;
  border-radius: 10px;
  box-shadow: 0 0 10px var(--box-shadow);
  background: #fff;
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-gap: 1em;
  box-sizing: border-box;
}

.logo {
  align-self: end;
  margin: 1em 0 2em 0;
  height: 5em;
  width: auto;
  fill: var(--box-shadow);
}
.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.footerButtons {
  display: flex;
  flex-direction: row;
  margin: 0.5em 0;
}

.footerButton {
  margin: 0.5em 0;
  color: #5D5D5D;
  text-decoration: none;
  transition: all 300ms;

  &:hover {
    text-decoration: underline;
  }
}

.spacer {
  flex-grow: 10000;
}

.error {
  color: var(--error);
  font-weight: 600;
}

.errorShake {
  animation: errorShake .8s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}


@keyframes errorShake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-3px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(3px, 0, 0);
  }
}