.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  overflow: hidden;
}

.brandPicker {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.iconBucketWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  transition: 200ms;
}

.iconBucketWrap:hover {
  background: #f5f5f5;
}

.iconBucketContent {
  margin-left: 6px;
  margin-right: 4px;
  white-space: nowrap;
}
.iconBucketContentHeader {
  font-weight: bold;
  font-size: 11px;
  line-height: 1.4em;
}
.iconBucketContentSubHeader {
  font-weight: 200;
  font-size: 11px;
  line-height: 1.4em;
}

.iconBucket, .brandPlaceholderIconBucket {
  border: 2px solid var(--color);
  padding: 1px;
  border-radius: 50%;
  position: relative;
  /* overflow: hidden; */
  cursor: pointer;
  --icon-size: 32px;
  --nav-text: #fff;
  width: auto;
  height: auto;
}

.brandPlaceholderIconBucket {
  height: 32px;
  width: 32px;
  border: 2px dotted var(--color);
}

.iconBucket:after, .brandPlaceholderIconBucket:after {
  content: "";
  background: var(--color);
  opacity: 1;
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 10px;
  height: 10px;
  transition: 200ms;
  border-radius: 50%;
  transform: translate(50%, 50%);
  border: 2px solid #fff;
}

.brandPlaceholderIconBucket:after {
  opacity: 0.2;
}

.iconBucket:hover:after, .brandPlaceholderIconBucket:hover:after {
  opacity: 0.9;
}

.placeholderIconBucket {
  border: 2px dashed var(--color);
}

.popup {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0.5em;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 2;
  max-height: 90vh;
}

.brandRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  background: var(--background);
  cursor: pointer;
  transition: 200ms;
  flex-shrink: 0;
  --nav-text: #fff;
}
.brandRow:hover {
  background: var(--color);
}

.brandName {
  margin-left: 8px;
}

.brandPlaceholderIcon {
  width: 30px;
  height: 30px;
  border: 2px dotted rgba(0,0,0,0.4);
  border-radius: 50%;
  background: rgba(255,255,255, 0.3);
}