.section {
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 200ms;
}

.section:first-child {
  padding-top: 8px;
}

.section:first-child:after {
  content: none;
}

.sectionHeader {
  font-size: 10px;
  font-weight: 600;
  color: #636169;
  letter-spacing: 1px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.button {
  font-size: 10px;
  padding: 2px 4px;
  border-radius: 4px;
  border: 1px solid #aaa;
  transition: 200ms;
  cursor: pointer;
  background: #fff;
}

.button:hover {
  color: #fff;
  background: var(--primary-color);
  border-color: var(--primary-color);
}