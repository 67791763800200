.button {
  border: none;
  border-radius: 4px;
  outline: none;
  background: none;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: var(--icon-size, 32px);
  height: var(--icon-size, 32px);
  padding: 0;
  margin: 0;
  aspect-ratio: 1;
  transition: 200ms;
  background-color: transparent;
  cursor: pointer;
  color: hsl(220, 13%, 46%);

  &:hover {
    background: hsl(220, 20%, 97%);
  }

  &.dangerous {
    &:hover {
      background: hsl(0, 58%, 81%);
      color: hsl(0, 100%, 38%)
    }
  }

  svg {
    width: 65%;
    height: 65%;
    display: block;
  }
}

.small {
  --icon-size: 24px;
}