.selectContainer {
  display: flex;
  padding: 10px;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  font-size: 1em;
  height: 3em !important;

  .icon {
    border-radius: 50%;
  }
}
