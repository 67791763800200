.suggestConcepts {
  margin: 5px 0px 10px 0px;
}

.suggestConceptWrap {
  margin: 10px 17px;
}
.suggestConceptWrap .clipboard {
  margin: 0 10px 0 10px;
  transform: translate(0, -50%);
}

.suggestConcept {
  padding: 10px;
  border-radius: 6px;
  position: relative;
  background: #fff;
}

.conceptName {
  color: #444;
  font-weight: bold;
}

.phrasesPartition {
  color: #444;
  font-weight: lighter;
}

.suggestConceptWords {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.5em -4px;
}

.suggestConceptWordsTitle {
  font-size: 11px;
  letter-spacing: 0.03em;
  color: #444;
}

.suggestConceptWord {
  margin: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 300;
  padding: 2px 7px;
}

.superString {
  color: red;
}

.suggestConceptCreate, .activeConceptCreate {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #ddd;
  transition: 200ms;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.suggestConceptCreate:hover {
  background: rgba(82, 79, 159, 0.2);
}

.suggestConceptCreateButton, .activeConceptCreateButton {
  background: #eee;
  border-radius: 6px;
  padding: 6px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  pointer-events: none;
  opacity: 0;
  transition: 200ms;
  font-size: 13px;
}

.suggestConceptCreate:hover .suggestConceptCreateButton {
  opacity: 1;
}

.activeConceptCreate {
  background: rgba(82, 79, 159, 0.2);
}
.activeConceptCreate:hover {
  background: rgba(82, 79, 159, 0.4);
}
.activeConceptCreateButton {
  opacity: 1;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttons {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  transform: translate(6px, -50%);
  transition: 200ms;
}
.buttons button {
  font-size: 10px;
  padding: 4px 6px;
  border-radius: 100000em;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 200ms;
  background: #dacafa;
  color: #3c344e;
  margin: 0 3px;
}

.buttons button:hover {
  background: #a596c2;
  color: #fff;
}

.buttons .conceptDelete {
  background: #fce4e4;
  color: var(--error-text);
}

.buttons .conceptDelete:hover {
  background: rgb(252, 163, 163);
}