.svg {
  display: block;
  overflow: visible;
}

.tone1 {
  fill: var(--icon-tone1);
  opacity: var(--icon-tone1-opacity);
  transition: all var(600ms);
}

.tone2 {
  fill: var(--icon-tone2);
  opacity: var(--icon-tone2-opacity);
  transition: all var(600ms);
}

.botAntena.animated {
  animation: botAnimation 2s infinite;
  animation-timing-function: linear;
  transform-origin: bottom center;
}

@keyframes botAnimation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}