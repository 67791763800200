.containerWrap {
  background: var(--primary-w90-color);
  min-height: 100vh;
}

.router {
  margin: 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  transition: 200ms;
  box-sizing: border-box;
  padding: 12px 16px;
  position: relative;
  max-width: 1024px;
  margin: 0 auto;
}

.section {
  border-bottom: 1px solid #999;
  margin-bottom: 1em;
  padding-bottom: 1em;
  &:last-child {
    border-bottom: none;
  }

  h2 svg {
    height: 1em;
    margin-right: 0.25em;
  }

  a {
    color: #7e4b9e;
    letter-spacing: -0.02em;
    text-decoration: none;
    text-decoration-color: #ddd;
    &:hover {
      text-decoration: underline;
    }
  }
  strong {
    color: #7e4b9e;
    letter-spacing: -0.02em;
  }

  video {
    max-width: 100%;
    width: 100%;
  }
}