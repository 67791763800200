.icon {
  position: relative;
  background: #655386;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
  box-shadow: 0 0 1px var(--box-shadow);
  transition: 300ms;
  border-radius: calc(var(--icon-size, 32px) * 0.17543103448);
  width: var(--icon-size, 32px);
  height: var(--icon-size, 32px);

  /*fixed subpixel rendering*/
  transform: translateZ(0);
}
.icon.rounded {
  border-radius: 50%;
}

.icon:link {
  cursor: pointer;
}

.img {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: calc(var(--icon-size, 32px) * 0.17543103448);
  overflow: hidden;
}

.icon svg {
  width: 75%;
  height: 75%;
}


.content {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0.5em;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 2;
}

.header {
  padding: 0.5em 1em;
  background: var(--error);
  color: #fff;
  font-size: 1.5em;
  font-weight: 600;
}

.deleteButton {
  margin: 0.5em 1em 1em 1em;
  background: var(--error-input);
  color: var(--error-text);
  border: 1px solid var(--error);
  border-radius: 0.25em;
  padding: 0.5em 1em;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    box-shadow: 0 2px 4px var(--error-border);
    background: var(--error);
    color: #fff;
  }

  &.disabled {
    background: var(--accent-disabled);
    color: var(--accent-text);
    border-color: var(--accent-disabled);
    cursor: initial;
    &:hover {
      box-shadow: none;
    }
  }
}

.input {
  display: block;
  outline: 0;
  font-size: 1em;
  padding: 0.5em;
  border: 1px solid var(--text);
  background: #fff;
  border-radius: 0.5em;
  margin: 0.5em 1em;
}

.text {
  margin: 0.5em 1em 0 1em;
  line-height: 1.5em;
}




@supports (mix-blend-mode: color) {
  .grayscale .img:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #D8D8D8;
    z-index: 1;
    border-radius: 50%;
    mix-blend-mode: color;
  }
}

@supports not (mix-blend-mode: color) {
  .grayscale .img {
    filter: grayscale(100%);
  }
}