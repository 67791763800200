.button {
  --font-size: 14px;
  --border-color: hsl(220, 16%, 84%);
  --color: hsl(218, 24%, 27%);
  --background: hsl(0, 0%, 100%);
  --icon-size: 20px;
  --border-radius: 8px;
  --chevron-icon-size: 14px;
  --line-height: 20px;
  --gap: 8px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  gap: var(--gap);
  transition: 200ms;
  color: var(--color);
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size);
  line-height: var(--line-height);
  border: 1px solid var(--border-color);
  background: var(--background);
  text-decoration: none;
  font-variation-settings: 'wght' 400;
  border-radius: var(--border-radius);
  white-space: nowrap;
  font-family: var(--main-font);

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.small {
    --icon-size: 16px;
    --font-size: 12px;
    --gap: 6px;
    --border-radius: 5px;
    --chevron-icon-size: 12px;
    --line-height: 17px;
    padding: 5px 11px;
  }

  &.hoverable {
    cursor: pointer;

    &:hover {
      --background: hsl(0, 0%, 98%);
      --border-color: hsl(217, 16%, 70%);
      font-variation-settings: 'wght' 600;
      letter-spacing: -0.019em;
      --stroke-width: 2;
    }
  }
  &.disabled {
    cursor: not-allowed;
    --color: hsl(0, 0%, 90%);
    --border-color: hsl(0, 0%, 90%);
  }

  &.primary {
    color: #fff;
    --background: hsl(226, 24%, 48%);
    border: 1px solid transparent;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

    &.hoverable:hover {
      --background: hsl(226, 24%, 40%);
      border-color: transparent;
    }
  }

  &.dangerous {
    --color: hsl(218, 24%, 27%);
    border: 1px solid transparent;

    &.hoverable:hover {
      --background: hsl(0, 58%, 81%);
      color: hsl(0, 100%, 38%);
      border-color: transparent;
    }
  }

  &.active {
    --color: hsl(218, 30%, 21%);
    --background: hsl(220, 20%, 96%);
    font-variation-settings: 'wght' 600;
    letter-spacing: -0.019em;
  }
}

.icon {
  min-width: var(--icon-size);
  height: var(--icon-size);
  display: block;
  flex-shrink: 0;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.group {
  --border-color: hsl(220, 16%, 84%);
  --border-radius: 8px;
  display: inline-flex;
  flex-direction: row;
  border: 1px solid var(--border-color);
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  overflow: hidden;
  flex-shrink: 0;
  border-radius: var(--border-radius);

  &.small {
    --border-radius: 5px;
  }

  .button {
    border: 1px solid transparent;
    border-radius: 0;
    border: none;

    &.hoverable {
      &:hover {
        border: none;
        border-color: transparent;
      }
    }

    &:first-child {
      border-left: none;
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }
    &:last-child {
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }
  }
}

.groupDivider {
  background: var(--border-color);
  width: 1px;
  flex-shrink: 0;
  flex-grow: 0;
}

.chevron {
  width: var(--chevron-icon-size);
  height: var(--chevron-icon-size);
  margin: 0 -3px;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.dot {
  --size: 5px;
  margin-right: -3px;
  height: var(--size);
  width: var(--size);
  border-radius: 10em;
  background: hsl(220, 16%, 64%);

  &.errorDot {
    background: hsl(0, 100%, 50%);
    outline-offset: 2px;
  }
  &.successDot {
    background: hsl(120, 57%, 53%);
  }
  &.warningDot {
    background: hsl(58, 57%, 53%);
  }
}
