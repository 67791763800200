.wrap {
  max-height: 100%;
  flex-basis: 0;
  flex-grow: 1;
  height: 0;

  & > div {
    margin-right: auto;
    max-width: 100%;
  }
}