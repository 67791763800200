.container {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  position: relative;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  flex-basis: 0;
  flex-grow: 1;
  gap: 3em;
}

.draggingOver {
  background: var(--primary-w90-color);
  border-radius: 4px;
}

.buttons {
  position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    transform: translateX(-115%);
}