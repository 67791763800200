.container {
  width: var(--nav-width);
  border-right: 1px solid var(--border-color);
  --background-color: #fff;
  background: var(--background-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  top: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  transition: 0.3s cubic-bezier(0.05, 0.61, 0.41, 0.95);
  --icon-size: 24px;
  --icon-padding: 8px;
  --logo-size: 32px;
  --row-color: hsl(264, 43%, 28%);
  font-family: var(--main-font);
}

.logo {
  display: flex;
  flex-direction: row;
  --icon-size: 32px;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  padding: 0px 16px;
  gap: 4px;
  flex-basis: 0;
  overflow: auto;
  transition: padding 0.3s cubic-bezier(0.05, 0.61, 0.41, 0.95);

  .minimized & {
    padding: 0 calc((var(--nav-width) - var(--icon-size) - 2 * var(--icon-padding)) / 2);
  }
}

.minimizer {
  position: absolute;
  right: 0;
  align-self: center;
}
.minimized .minimizer {
  transform: rotate(180deg);
}

.row {
  color: hsl(220, 43%, 11%);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  gap: 12px;
  padding: var(--icon-padding);
  text-decoration: none;
  border-radius: 6px;

  &.hoverable:hover {
    background: hsl(264, 20%, 92%);
  }

  &.active,
  &.partialActive {
    background: hsl(264, 20%, 97%);
  }

  &.spacerRow {
    min-height: 40px;
  }
}

.footer {
  margin: 0px 16px 32px 16px;
  border-top: 1px solid var(--border-color);
  flex-shrink: 0;
  background: var(--background-color);
}

.header {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
  background: var(--background-color);
  color: hsl(260, 33%, 7%);
  transition: 200ms;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.row {
  display: flex;
  flex-direction: row;
  position: relative;
  //height needs to exist for animation to be smooth
  height: calc(var(--icon-size) + var(--icon-padding) * 2);
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
  color: var(--row-color);
  transition: 200ms;
  overflow: hidden;

  &.clickable {
    cursor: pointer;
  }

  .minimized & {
    gap: 0;
  }
}
.logoText {
  white-space: pre;
  height: var(--logo-size);
  svg {
    height: 100%;
  }
}
.rowText {
  white-space: pre;
}
.minimized .rowText, .minimized .logoText {
  transition: 0.2s cubic-bezier(0.05, 0.61, 0.41, 0.95), opacity 0.1s ease-out;
  opacity: 0;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px 20px 24px calc(16px + var(--icon-padding));
  gap: 1em;
}

.rowIcon,
.logoIcon {
  flex-shrink: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  
  svg, img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.rowIcon {
  height: var(--icon-size);
  width: var(--icon-size);
  display: block;
}
.logoIcon {
  width: var(--logo-size);
  height: var(--logo-size);
}
