.container {
  // border-left: 5px solid hsl(242, 33%, 70%);
  padding: 10px 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
  background-color: hsl(240, 40%, 96%);
  gap: 10px;

  &:hover {
    .buttons {
      opacity: 1;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
}

.title {
  color: hsl(0, 0%, 13%);
  font-size: 12px;
  font-weight: bold;
  
  span {
    color: #777;
    font-weight: normal;
  }
}
.text {
  color: hsl(260, 1%, 45%);
  font-size: 12px;
  line-height: 1.5em;
  margin-top: 3px;
  margin-bottom: 2px;
}


.buttons {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 2px;
  right: 2px;
  opacity: 0;
  transition: 200ms;
}