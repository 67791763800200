.container {
  position: relative;
  border-radius: 10px;
  height: 6px;
  overflow: hidden;
}
.background {
  background: var(--color, #d3d3d3);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  opacity: 0.1;
  transition: 200ms;
}

.bar {
  position: relative;
  height: 100%;
  background: var(--color, #d3d3d3);
  border-radius: 10px;
  transition: 200ms;
}