.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: visible;
  max-width: 1024px;

  & + & {
    margin-top: 64px;
  }

  &.small {
    gap: 8px;
    & + & {
      margin-top: 40px;
    }
  }
}