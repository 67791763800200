.warning {
  --tone1: #fff;
  --tone2: #E04F5F;
}

.button {
  border: 1px solid #E04F5F;
  border-radius: 7px;
  transition: 200ms;
  cursor: pointer;
  background: hsl(353, 70%, 59%);
  color: #fff;
  outline: none;

  font-size: 18px;
  line-height: 26px;
  color: #FFFFFF;
  letter-spacing: -0.41px;
  padding: 11px 23px;
  align-self: flex-start;

  margin: 16px 0 0 0;
  width: 100%;
  max-width: 100%;
  z-index: 1;
  transition: 200ms;
  text-align: center;
  box-sizing: border-box;
}
.button:not(:disabled):hover {
  background: hsl(353, 70%, 50%);
}