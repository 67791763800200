.container {
  display: flex;
  gap: 16px;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
  p {
    margin: 0;
  }
}

.name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  max-height: 30px;
  min-height: 30px;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #101828;
}

.headline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  -webkit-line-clamp: 2;
  min-height: 20px;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #98a2b3;
}
