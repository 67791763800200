.container {
  flex-shrink: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.input {
  flex-grow: 1;
  border-radius: 1000em;
  border: 1px solid #E3DCDC;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
  background: #fff;
  outline: none;
  padding: 8px 32px 8px 36px;
  font-size: 16px;
  color: var(--text);
  transition: 200ms;
}
.input:focus {
  background: var(--accent-input);
  border-color: #a8a7d0;
}

.searchIcon {
  position: absolute;
  top: 50%;
  left: 16px;
  height: 14px;
  width: auto;
  transform: translate(0, -50%);
  fill: var(--text);
}

.deleteIcon {
  position: absolute;
  top: 50%;
  right: 12px;
  height: 14px;
  width: auto;
  transform: translate(0, -50%);
  fill: #aaa;
  cursor: pointer;
  transition: 200ms;
  opacity: 0;
  visibility: hidden;
}

.deleteIcon:hover {
  fill: var(--primary-color);
}

.hasValue .deleteIcon {
  opacity: 1;
  visibility: visible;
}