.linesSection {
  display: flex;
  gap: 0.5em;
}

.lineSection {
  display: flex;
    background: hsl(220, 0%, 100%);
    border: 1px solid hsl(220, 5%, 95%);
    box-shadow: 0px 1px 2px hsla(220, 43%, 11%, 0.05);
    border-radius: 8px;
  
}

.body {
  padding: 8px;
  width: 90%;
}
.navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10%;
}