.container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
  grid-auto-rows: 1fr;
  grid-auto-rows: min-content;
  flex-shrink: 0;
  align-content: start;
  background: #ffffff;
  overflow: hidden;
  padding: 21px 33px 51px;
}

.name {
  font-size: 12px;
  color: #808080;
  grid-column-start: 1;
  grid-column-end: -1;
  margin-top: 1.2em;
  line-height: 1.4em;
  margin-bottom: 4px;
  margin-left: 0px;
  font-weight: 400;
}
.name:first-child {
  margin-top: 0;
}

.row {
  position: relative;
  grid-column-start: 1;
  grid-column-end: -1;
  height: 7px;
  background: #f7f7f7;
  border-radius: 10000em;
  transition: all 500ms;
  background: linear-gradient(
    90deg,
    hsl(0deg, 83%, 68%) 0%,
    hsl(13deg, 100%, 68%) 10%,
    hsl(27deg, 100%, 64%) 20%,
    hsl(39deg, 100%, 60%) 30%,
    hsl(50deg, 100%, 55%) 40%,
    hsl(60deg, 96%, 53%) 50%,
    hsl(67deg, 90%, 59%) 60%,
    hsl(76deg, 83%, 63%) 70%,
    hsl(86deg, 73%, 65%) 80%,
    hsl(97deg, 62%, 66%) 90%,
    hsl(111deg, 50%, 67%) 100%
  );

}

.circle {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background: var(--color);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  transition: 200ms;
  pointer-events: none;
}

.icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 1;
  transition: 200ms;
  cursor: pointer;
}

.row:hover {
  z-index: 1;
  background-color: hsla(262, 37%, 31%, 0.12);
}





.tooltipRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 16px;
}