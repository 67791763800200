.container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  background: hsl(0, 0%, 95%);
  color: hsl(218, 24%, 27%);
  border-radius: 20em;
  border: 1px solid transparent;
  --icon-size: 16px;

  --gap: 4px;
  gap: var(--gap);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  transition: 200ms;

  &.deletable {
    padding: 2px calc(6px + var(--icon-size) / 2);
    &:hover {
      padding: 2px 6px;

      .delete {
        opacity: 1;
        width: var(--icon-size);
        height: var(--icon-size);
      }
    }
  }

  &.add {
    border: 2px dashed hsl(220, 68%, 27%);
    
    background: linear-gradient(
      110deg,
      hsl(280, 41%, 90%) 8%,
      hsl(272, 37%, 92%) 18%,
      hsl(273, 36%, 89%) 33%
    );
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  &.primary {
    background: hsl(270, 90%, 96%);
    color: hsl(273, 69%, 23%);
  }

  &.hoverable {
    cursor: pointer;
    &:hover {
      background: hsl(0, 0%, 93%);
      border-color: hsl(0, 0, 84%);
    }
    &.add:hover {
      background: hsl(0, 0%, 98%);
      border-color: hsl(217, 16%, 70%);
    }
  }

  &.small {
    font-size: 10px;
    line-height: 15px;
    font-weight: 400;
    padding: 0px 4px;
    --icon-size: 10px;
    --gap: 2px;
  }
}

.dot {
  --size: 6px;
  width: var(--size);
  height: var(--size);
  border-radius: 1em;
  background: hsl(220, 77%, 70%);
}

.icon {
  width: var(--icon-size);
  height: var(--icon-size);
  display: block;
  flex-shrink: 0;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.delete {
  display: block;
  flex-shrink: 0;
  transition: 200ms;
  cursor: pointer;
  opacity: 0;
  width: 0;
  height: 0;
  margin: -1px -1px -1px calc(var(--gap)/2 * -1);

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  &:hover {
    color: rgb(192, 49, 49);
  }
}


@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
