.container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--border-color);
  flex-shrink: 0;

  &.hasFooter {
    border-bottom: none;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  flex-shrink: 0;
  position: relative;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self:flex-start;
}
