.scrollable {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  flex-basis: 0;
}

.scroll {
  overflow: auto;
  display: flex;
  height: 100%;
  flex-basis: 0;
  flex-grow: 1;
  width: 100%;

   & > div {
     width: 100%;
   }
}

.infiniteScroll {
  width: 100%;
  position: relative;
}

.scrollable:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  background: radial-gradient(farthest-side at 50% 0%, var(--primary-a30-color), var(--primary-a00-color)) 100% 0;
  background-repeat: no-repeat;
  background-size: 100% 5px;
  height: 8px;
  z-index: 1;
}
.scrollable:after {
  content: '';
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  background: radial-gradient(farthest-side at 50% 100%, var(--primary-a30-color), var(--primary-a00-color)) 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 5px;
  width: inherit;
  height: 8px;
  z-index: 1;
}

.scrollableContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  height: 100%;
  box-sizing: border-box;
}

.scrollableContent:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: var(--primary-w90-color);
  z-index: 2;
}

.scrollableContent:after {
  position: relative;
  content: '';
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: var(--primary-w90-color);
  z-index: 2;
  margin: 0 -1em -0.5em -1em;
}

.listContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  min-height: calc(100% - 10px);
}

.listLoading {
  height: 2em;
  margin: 1em;
}

.noReviews {
  padding: 8px 30px;
  color: #6C70AB;
}

.noReviews strong {
  font-weight: strong;
  color: #3D3F74;
}

.prevLoad {
  align-self: center;
  margin-top: 0.5em;
  margin-bottom: 0.25em;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.prevLoad + * {
  border-top: 0;
}

.search {
  
  width: 100%;
}

.searchContainer {
  margin: 12px 0 4px 0;
  flex-shrink: 0;
  padding: 0 8px;
  box-sizing: border-box;
}


.placeholderReviewHolder {
  position: relative;
}

.placeholderReviewHolderButton, .placeholderReviewHolderButton:link, .placeholderReviewHolderButton:visited {
  font-size: 14px;
  color: #fff;
  padding: 12px 24px;
  background: #47326C;
  border-radius: 10000em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  outline: 0;
  border: none;
  cursor: pointer;
  transition: 200ms;
}

.placeholderReviewHolderButton:hover, .placeholderReviewHolderButton:link:hover, .placeholderReviewHolderButton:visited:hover {
  box-shadow: 1px 2px 1px 0 rgb(45, 30, 70);
  transform: translate(-50%, calc(-50% - 2px));
  color: #fff;
}

.selectedFilters {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  line-height: 1.9;
}

.filter {
  border-radius: 1000em;
  background: #47326C;
  color: #fff;
  fill: #fff;
  --tone1: #fff;
  --tone2: #fff;
  padding: 8px 13px 8px 12px;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin: 2px 0;
  line-height: 1.1;
}

.filter svg {
  height: 10px;
}
.filter svg + span {
  margin-left: 4px;
}

.clearFilters {
  cursor: pointer;
}

.clearFilters:hover {
  text-decoration: underline;
}

.tags {
  display: flex;
  flex-direction: row;
  margin: 2px 8px 3px 6px;
}

.tags > .filter {
  margin: 2px;
}

.delete {
  height: 100%;
  display: block;
  margin-left: 4px;
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.delete svg {
  height: 8px;
  width: auto;
  display: block;
  transition: 200ms;
  fill: #fff;
  opacity: 1;
}

.delete:hover svg {
  transform: scale(1.3);
}

.tooltip {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.categorizeButton {
  cursor: pointer;
  padding: 0.5em;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
}
.categorizeButton:hover {
  background: var(--primary-color);
}
.categorizeButton svg {
  height: 1em;
  width: auto;
  margin-right: 4px;
  fill: #fff;
}

.tooltipContainer {
  pointer-events: auto;
  padding: 0;
}



.delightedScore {
  font-size: 10px;
  background: var(--color);
  display: inline-flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 1000em;
  flex-direction: row;
  padding: 3px 8px;
  font-weight: bold;
}

.delightedScore svg {
  width: auto;
  height: 6px;
  display: block;
  --tone1: #fff;
  stroke-width: 8px;
  flex-shrink: 0;
  flex-grow: 0;
}

.delightedScore .divider {
  display: block;
  margin: 0 5px;
  border-radius: 1000em;
  width: 2px;
  height: 14px;
  background: rgba(255,255,255,0.3);
}

.tooltip {
  z-index: 10;
  background: rgba(0,0,0,0.7);
  position: absolute;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  transition: 200ms;
  
  &:hover {
    background: rgba(0,0,0,0.9)
  }
}

.container {
  position: relative;
  border: 1px var(--border-color) solid;
  border-radius: 5px;
}

.editButton {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%; 
  left: 50%;
}