.icon {
  height: 14px;
  width: 14px;
  display: block;
  svg {
    height: 100%;
    width: 100%;
  }
}

.popupImg {
  display: flex;
  flex-direction: row;
}
.changeIconTooltip {
  cursor: pointer;
}
