.headerInput {
  display: flex;
  flex-direction: column;

  .label {
    display: flex;
    flex-direction: row;
    background: transparent;
    transition: 200ms;
    padding: 3px;
    border-radius: 5px;

    &:hover {
      background: #fafafa;
    }
  }

  .input {
    background: transparent;
    margin: 0;
    padding: 0;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.03em;
    color: #333;
    border: none;
    outline: none;
    flex-grow: 1;
  }

  textarea {
    background: transparent;
    margin: 0;
    padding: 0;
    font-size: 26px;
    font-weight: 500;
    color: #333;
    border: none;
    outline: none;
    flex-grow: 1
  }
}

.inputs {
  display: flex;
  flex-direction: column;

  textarea {
    margin: 6px 10px 6px 4px;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    outline: 0;
    color: rgb(136, 136, 136);
    max-width: 1024px;
    line-height: 160%;
    height: calc(var(--width) / 10em);
    resize: none;
    height: 100%;
    font-size: 1em;
    padding: 1.5em;
    border: none;
    background: rgb(254, 252, 232);
    font-family: var(--font);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    border-radius:8px;
  
    &:hover {
      box-shadow: 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
    }
  }
}