.dateContainer {
  background: #e7dbec;
  cursor: pointer;
  color: rgb(92, 49, 117);
  padding: 5px;
  border-radius: 6px;
  // border: 1px solid var(--text);
  transition: 200ms;
}

.dateContainer:hover {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}

.popup {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0.5em;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 2;
  max-height: 90vh;
}

.typePicker {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #eee;
}

.typePickerRow {
  padding: 0.5em;
  border-top: 1px solid #eee;
  cursor: pointer;
  transition: 200ms;
  font-size: 16px;
}

.typePickerRow:first-child {
  border-top: none;
}

.typePickerRow:hover {
  background: var(--primary-w70-color);
}

.typePickerRow.selected {
  background: var(--primary-color);
  color: #fff;
}

.pickerContainer {
  display: flex;
  flex-direction: row;
  max-height: 100%;
  height: 300px;
}

.valuePicker {
  min-width: 300px;
  font-size: 16px;
  text-align: center;
  overflow: auto;
}

.valuePickerRow {
  padding: 0.5em;
  border-top: 1px solid #eee;
  cursor: pointer;
  transition: 200ms;
}

.valuePickerRow.selected {
  background: var(--primary-color);
  color: #fff;
}

.valuePickerRow:first-child {
  border-top: none;
}

.valuePickerRow:hover {
  background: var(--primary-w70-color);
}

.popupHeader {
  text-align: center;
  padding: 1em;
  background: var(--primary-color);
  color: #fff;
  font-size: 13px;
}