.content {
  display: flex;
  flex-direction: column;
  padding: 10px 32px;

  &.small {
    padding: 10px 16px;  
  }
}

.fullHeight {
  min-height: 100%;
}