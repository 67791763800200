.container {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 0.5em;
  padding: 0.75em 1em;
}

.filedrop {
  background: #fff;
}
.filedropContent {
  border: 2px dashed #cfcfcf;
  border-radius: 12px;
  margin: 16px 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  color: var(--text);
  font-weight: 600;
  padding: 16px;
  letter-spacing: -0.01em;
  text-align: center;
  min-height: 100px;
  background: #fff;
  transition: 200ms;
}

.filedrop.hovering .filedropContent {
  background: #dedae2;
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.files {
  padding: 8px 0;
}

.file {
  color: var(--text);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
}
.file:first-child {
  margin-top: 0;
}

.file svg {
  margin-right: 6px;
  height: 16px;
  width: auto;
}

.deleteIcon {
  margin-right: 0;
  margin-left: 6px;
  --icon-tone1: var(--text);
  --icon-tone1-opacity: 1;
  transition: 200ms;
  cursor: pointer;
}

.deleteIcon:hover {
  --icon-tone1: var(--error);
  --icon-tone1-opacity: 1;
}

.spacer {
  flex-grow: 1000;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.csvTable{
  display: grid;
  grid-template-columns: repeat(7,1fr) ;
  margin-bottom: 1em;
  font-family: var(--font-code);
  font-size: 12px;

  .header{
    padding: .8em;
    border: 1px solid #fff;
    background-color: #f2ecf6;
  }
  .celldata{
    padding: .8em;
    background-color: rgb(249, 249, 249);
    border: 1px solid #fff;

  }
}

.specialnote{
  font-size: 13px;
  color: #444;
}

.csvColumn {
  margin: 0 0 1.5em 0;
  max-width: 650px;
}

.csvColumnName {
 color: #333;
 margin-bottom: 0.4em;
 font-size: 13px;
 font-family: var(--font-code);
 background: #ffffee;
 display: inline-block;
 padding: 3px;
 border-bottom: 1px solid #f7e491;
}

.csvColumnDescription {
  color: #8c89d3;
  font-size: 14px;
}

.csvColumnFormats {
  font-size: 14px;
  border-radius: .4em;
  margin-top: 1em;
  color: #333;
  background: #f2ecf6;
  padding: 1em;
}

.csvColumnFormatsTitle {
  font-weight: bold;
  color: #515151;
  line-height: 2em;
}

.csvColumnFormatsValue {
  color: #515151;
  margin-bottom: 0.25em;
  strong{
    color: #8c89d3;
    margin-right: .75em;

  }
}

.errors {
  color: red;
  margin: 0.5em 0 1em 0;
  font-weight: 400;
}
.errorMessage {
  color: red;
  margin: 0 0.5em;
}