.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--star-rating);
  border-radius: 10000px;
  font-size: 10px;
  line-height: 150%;
  padding: 0.2em 0.6em;
  color: var(--star-color, #5B5B5B);
  gap: 0.2em;

  svg {
    height: 1em;
    width: auto;
    --fill-opacity: 1;
  }
}