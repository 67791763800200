.container {
  width: 100vw;
  height: 100vh;
  color: hsl(218, 88%, 32%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  height: 20%;
  width: 20%;
}

.centerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;

  svg {
    height: 50%;
    width: 50%;
    display: block;
  }
}