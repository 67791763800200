.container {
  padding: 2px;
  min-width: 17px;
  display: inline-block;
  vertical-align: baseline;
  text-align: center;
  text-transform: capitalize;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.0002em;
  color: rgba(255, 255, 255, 0.55);
}
