.integrationRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f1ecf5;
  padding: 12px;
  border-radius: 11px;
  overflow: hidden;
  margin: 4px 0;
}

.iconTitle {
  padding: 0 10px;
}

.spacer {
  flex-grow: 1;
}


.integrationRowStatus {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}

.integrationDelete {
  height: 2em;
  width: auto;
  display: block;
  margin-left: 0.5em;
  padding: 0.5em;
  box-sizing: border-box;
  cursor: pointer;
  --icon-tone1: #797979;
  --icon-tone2: #C7C7C7;
  --icon-tone1-opacity: 1;
  --icon-tone2-opacity: 1;
  transition: 200ms;

  &:hover {
    --icon-tone1: var(--error);
    --icon-tone2: var(--error);
  }
  
  svg {
    display: block;
    height: 100%;
    width: auto;
    transition: 200ms;
  }
}