.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: hsl(220, 0%, 100%);
  border: 1px solid hsl(220, 5%, 95%);
  box-shadow: 0px 1px 2px hsla(220, 43%, 11%, 0.05);
  border-radius: 8px;
  overflow: hidden;
  transition: 200ms;
  text-decoration: none;
  position: relative;

  &:hover {
    .buttons {
      opacity: 1;
    }
  }

  &.notMachine {
    opacity: 0.2;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px;
  gap: 14px;
  background: hsl(220, 0%, 100%);
}

.logo {
  width: 20px;
  height: 20px;
  display: block;
  margin-top: 1px;
  svg {
    height: 100%;
    width: 100%;
    display: block;
  }
}

.header {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 6px;
}

.headerLeft {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
}
.title {
  display: flex;
  flex-direction: row;
  gap: 6px;
  color: hsl(260, 33%, 7%);
  font-size: 14px;
  line-height: 20px;
  min-height: 20px;
  position: relative;
  transition: 200ms;
  font-weight: 600;
  font-family: var(--main-font);
}

.stars {
  display: flex;
  flex-direction: row;
  color: hsl(0, 3%, 85%);
  --fill-opacity: 1;
  .icon {
    height: 14px;
    width: 14px;
    display: block;
    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.headerRight {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
}

.username {
  color: hsl(0, 0%, 37%);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: right;
  white-space: nowrap;
  flex-shrink: 0;
  transition: 200ms;
  letter-spacing: -0.02em;
}

.date {
  color: hsl(260, 2%, 72%);
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  transition: 200ms;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .icon {
    height: 13px;
    width: 13px;
    display: block;
    --stroke-width: 2;

    color: #b9a8c6;
    --tone1: #b9a8c6;
    --tone2: #8d8d8d;
    --tone3: #8d8d8d;
    --tone4: #8d8d8d;

    svg {
      height: 100%;
      width: 100%;
      display: block;
    }
  }
}
.footerIcons {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.buttons {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 2px;
  right: 2px;
  opacity: 0;
  transition: 200ms;
}

.body {
  color: rgb(16, 12, 24);
  font-size: 14px;
  line-height: 20px;
  position: relative;
  transition: 200ms;

  .line {
    margin-bottom: 15px;
    position: relative;
  }
}

.editButton {
  pointer-events: none;
  user-select: none;
}