.words {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.5em -4px;
}


.wordsTitle {
  font-size: 11px;
  letter-spacing: 0.03em;
  color: #444;
  margin-top: 6px;
}