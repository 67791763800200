.loading {
  height: 16px;
  width: 16px;
  margin: 0;
  color: #0A3F9A;
  background: hsl(220, 20%, 90%);
  border-radius: 50%;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}
.button {
  border: 1px gray solid;
  padding: 2px 5px;
  border-radius: px;
  cursor: pointer;
}