.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  z-index: 1;
  transition: 200ms;
  backdrop-filter: blur(4px);
  animation: backgroundAppear 200ms ease-in-out;
}

.content {
  background: #fff;
  box-shadow: 0 2px 20px rgba(0,0,0,0.3);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 2;
  color: #333;
  position: relative;
  max-height: 100%;
  max-width: 600px;
  text-align: center;
  padding: 16px 18px;
  gap: 8px;

  h2 {
    margin: 0;
    padding: 0;
    text-align: left;
  }
  p {
    text-align: left;
    margin: 0;
    padding: 0;
  }
}