
.body {
  color: rgb(16, 12, 24);
  font-size: 14px;
  line-height: 20px;
  position: relative;
  transition: 200ms;

  .line {
    margin-bottom: 15px;
    position: relative;
  }
}

.editButton {
  pointer-events: none;
  user-select: none;
}

.headerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  cursor: pointer;
}

.buttons {
  display: flex;
  gap: 1em
}