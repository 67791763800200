.container {
  width: var(--detail-width);
  border-left: 1px solid var(--border-color);
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  top: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  font-family: var(--main-font);
}