.container {
  display: flex;
  flex-direction: column;
  min-height: 0;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: var(--filters-width, 0);
  transition: 200ms;
}