.container {
  display: flex;
  flex-direction: row;
  line-height: 1.4em;
  font-size: 1em;
  border: 1px solid #fff;
  color: #524f9f; 
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: 0.15s;
  padding: 0.2em;
  margin: -0.2em;
  border-radius: 6px;
}

.container:hover{
  text-decoration: underline;
  text-decoration-color: #524f9f6d;
  background-color: #524f9f11;
}

.pencil {
  margin-left: 0.25em;
  margin-right: 0.25em;
  fill: var(--text);
  cursor: pointer;
  transition: 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pencil svg {
  height: 0.75em;
  width: auto;
}

.pencil:hover {
  fill: var(--primary-color);
}

.text {
  flex-grow: 1;
}

.spacer{
  flex-grow: 10000;
}

.container input {
  border: none;
  outline: none;
  font-size: 1em;
  background: #f7f4fd;
  flex-grow: 1;
  min-width: 0;
  border-radius: 5px;
}

.container:focus-within {
  background: #f7f4fd;
  padding: 6px 8px;
  margin: -6px -8px;
}

.trashIcon {
  cursor: pointer;
  display: flex;
  align-items: center;
  fill: var(--error);
  margin-left: 0.5em;
  --icon-tone1: #797979;
  --icon-tone2: #C7C7C7;
  --icon-tone1-opacity: 1;
  --icon-tone2-opacity: 1;
}
.trashIcon svg {
  height: 0.8em;
  width: auto;
}
.trashIcon svg:hover {
  --icon-tone1: var(--error);
  --icon-tone2: var(--error);
}