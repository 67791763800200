.container {
  display: flex;
  flex-direction: row;
  height: 110px;
  overflow: auto;
  padding: 2px;
  gap: 10px;
}

.image {
  height: 100%;
  width: auto;
  display: block;
  box-shadow: 0 0 2px 1px var(--border-color);
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;

  img {
    height: 100%;
    width: auto;
    display: block;
  }
}