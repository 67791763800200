.card {
  background: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
  border: 1px solid var(--border-color);
}

.header {
  color: var(--text);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}