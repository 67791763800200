.imageContainer {
  cursor: zoom-in;
  margin: 10px 0;
}

.img {
  max-width: 100%;
  max-height: 100%;
}

.zoomedImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}