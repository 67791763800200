.container {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: stretch;
  position: relative;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  background: var(--primary-w90-color);
  flex-basis: 0;
  flex-grow: 1;
  grid-gap: 12px;
  gap: 12px;
}

.card {
  border: 1px solid #f0f0f0;
  background: #fff;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  flex-shrink: 0;
  flex-grow: 0;
  width: calc(50% - 6px);
  height: auto;
}