.reviewWrap {
  position: relative;
  border-radius: 4px;
  box-shadow: 0 1px 6px rgba(0,0,0,0.07);
  background: #fff;
  z-index: 1;
  max-width: 1024px;
  margin: 0 auto;
}

.noReviewWrap {
  box-shadow: none;
}

.reviewCommentsWrap {
  transition: 400ms;
  margin: 6px 10px 6px 4px;
}

.reviewCommentsWrap.machineInactive {
  transform: scale(0.90);
}

.review {
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}

.textContainer {
  padding: 15px 13px;
}

.header + .textContainer {
  padding-top: 0;
}

.line {
  color: rgb(16, 12, 24);
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
  position: relative;
  transition: 200ms;
}

.blurry .line, .blurry .title, .blurry .date, .blurry .flag {
  color: transparent;
  text-shadow: 0 0 10px rgba(16, 12, 24, 0.5);
  pointer-events: none;
  user-select: none;
}

.delightedScore {
  font-size: 10px;
  background: var(--color);
  display: inline-flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 1000em;
  flex-direction: row;
  padding: 3px 8px;
  font-weight: bold;
  margin-bottom: 5px;
}

.delightedScore svg {
  width: auto;
  height: 6px;
  display: block;
  --tone1: #fff;
  stroke-width: 8px;
  flex-shrink: 0;
  flex-grow: 0;
}

.delightedScore .divider {
  display: block;
  margin: 0 5px;
  border-radius: 1000em;
  width: 2px;
  height: 14px;
  background: rgba(255,255,255,0.3);
}

.line:last-child {
  margin-bottom: 0;
}

.csvYoutube .line {
  margin-bottom: 4px;
}

.hasConcept.line, .hasConcept.title {
  color: #CFCED1;
}

.reviewWrap:hover .hasConcept.line, .reviewWrap:hover .hasConcept.title {
  color: #100c18;
}

.machineActive .line, .machineActive .title {
  color: #100c18;
}

.header {
  padding: 13px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerLeft {
  flex-grow: 1;
}

.titleContainer {
  margin-bottom: 15px;
}

.title {
  color: #100c18;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  transition: 200ms;
  font-weight: 600;
  font-family: var(--font);
}

.headerAppIcon {
  --icon-size: 16px;
  margin-right: 6px;
  display: inline-block;
}


.starRatingSelected {
  fill: #FFB135;
}
.starRating {
  display: flex;
  flex-direction: row;
  fill: #DAD8D8;
  margin-top: 6px;
}
.starRating svg {
  height: 13px;
  width: auto;
  margin-left: 2px
}
.starRating svg:first-child {
  margin-left: 0;
}

.headerDateText {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.headerUserText {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
}

.date {
  color: #B7B6B9;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  transition: 200ms;
}

.flag {
  font-size: 12px;
  line-height: 16px;
  margin-left: 2px;
  margin-right: -4px;
  display: inline-block;
}

.author {
  color: #5f5f5f;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: right;
  white-space: nowrap;
  flex-shrink: 0;
  transition: 200ms;
  letter-spacing: -0.02em;
}
.twitterLink {
  text-decoration: underline;
  color: #586d9f;
  &:hover {
    text-decoration: underline;
  }
}

.twitterRating {
  font-size: 13px;
  color: rgb(159, 147, 180);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.downloadButton {
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  transform: translate(30%, 30%);
}
.reviewWrap:hover .downloadButton {
  visibility: visible;
  opacity: 1;
}




.buttons {
  position: absolute;
  bottom: -6px;
  right: 20px;
  display: grid;
  grid-gap: 6px;
  grid-auto-columns: 20px;
  grid-auto-rows: 20px;
  grid-auto-flow: column;
  opacity: 0;
  visibility: hidden;
  transition: 300ms;
}

.button {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  font-size: 11px;
  background: #EBEBEB;
  box-shadow: 0 1px 3px rgba(0,0,0,0.5);
  fill: #B0B0B0;
  color: #B0B0B0;
  transition: 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button:hover {
  background: var(--primary-color);
  fill: #fff;
}

.button svg {
  width: auto;
  height: 50%;
}

.reviewWrap:hover .buttons {
  opacity: 1;
  visibility: visible;
}

.highlightOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: transparent;
  z-index: 1;
  pointer-events: none;
}

.highlightOverlay span:not(.highlight) {
  background: transparent;
  color: transparent;
  opacity: 0;
  visibility: none;
}

.highlight {
  opacity: 1;
  color: rgb(24, 24, 12);
  background: rgba(255,255,0, 0.2);
  box-shadow: inset 0 -2px 0 rgba(255,255,0, 1);
}

.overlay {
  z-index: 1;
  overflow: hidden;
}

.sentiment {
  background: var(--highlight-color);
  border-radius: 4px;
  transition: 200ms;
  color: #100c18;
  box-shadow: inset 0 -1px 0 var(--color);
}

.overlay.hasConcept .sentiment:not(.activeConcept):not(.machineActive) {
  box-shadow: none;
}

.sentiment:hover {
  background: var(--hover-highlight-color);
}

.machineActive .overlay .sentiment {
  background: transparent !important;
  color: #100c18 !important;
}

.overlay.hasConcept .sentiment {
  color: #CFCED1;
  background: var(--highlight-faded-color);
}
.overlay.hasConcept .sentiment:hover {
  background: var(--hover-highlight-faded-color);
}

.overlay.hasConcept .sentiment.activeConcept {
  color: #100c18;
  background: var(--highlight-color);
}

.overlay.hasConcept .sentiment.activeConcept:hover {
  background: var(--hover-highlight-color);
}

.reviewWrap:hover .sentiment {
  color: #100c18;
  background: var(--highlight-color);
}

.reviewWrap:hover .sentiment:hover {
  background: var(--hover-highlight-color);
}


.comments {
  display: flex;
  flex-direction: column;
  background: #fff;
  margin: 0 7px; 
  border-radius: 0 0 6px 6px;
  z-index: 0;
}

.response {
  display: flex;
  flex-direction: column;
  background: #fff;
  margin: 0 7px; 
  border-radius: 0 0 6px 6px;
  z-index: 0;
  padding: 10px;
  font-size: 14px;
  line-height: 1.5em;
  color: #555;
}
.responseTitle {
  font-size: 12px;
  font-weight: bold;
  color: #777;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}



.machineOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: transparent;
  z-index: 2;
  color: #100c18;
  pointer-events: none;
  user-select: none;
}

.machineOverlay .selected {
  background: rgba(105, 103, 161, 1);
  border-radius: 4px;
  color: #fff;
  box-shadow: inset 0 -2px 0 rgb(85, 82, 150);
}

.machineLine {
  background: transparent;
  transition: 200ms;
}

.machineOverlayButton {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(82, 79, 159, 0.2);
  z-index: 10;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 200ms;
  cursor: pointer;
}

.machineOverlayButton svg {
  width: 30px;
  height: 50px;
  fill: rgba(98, 69, 145, 0.33);
  opacity: 0.9;
  transition: 200ms;
}

.machineOverlayButton:hover {
  background: rgba(82, 79, 159, 0.4);
}

.machineOverlayButton:hover svg {
  opacity: 1;
}


.noEvents {
  pointer-events: none;
}

.machineOverlayText {
  margin-top: 4px;
}






.placeholderReview .title {
  background: #D9D9D9;
  color: #D9D9D9;
  border-radius: 4px;
  width: 357px;
  height: 24px;
  margin-right: 10px;
}

.placeholderReview .textContent {
  background: #F1F1F1;
  color: #F1F1F1;
  border-radius: 4px;
  height: 80px;
  margin: 15px 13px;
}

.placeholderReview .headerRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.placeholderReview .headerText {
  height: 10px;
  width: 82px;
  background: #EEEEEE;
  border-radius: 4px;
  margin-bottom: 4px;
}

.placeholderReview .author {
  height: 10px;
  width: 72px;
  background: #EEEEEE;
  border-radius: 4px;
}

.youtube {
  position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
  height: 0;
  margin-bottom: 10px;
}

.youtube iframe {
  position: absolute;
	top: 0;
	left: 0;
	width: 100%;
  height: 100%;
  border: none;
  border-radius: 4px;
  overflow: hidden;
}

.chevron {
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  display: block;
  margin-left: 4px;
  opacity: 0.4;
  cursor: pointer;
  transition: 200ms;
}

.chevron:hover {
  opacity: 1;
}

.chevron svg {
  width: 100%;
  height: 100%;
  display: block;
}

.watermark {
  position: absolute;
  bottom: 4px;
  right: 4px;
  height: 15px;
  width: auto;
  display: block;
}
.watermarkCover {
  position: absolute;
  bottom: 4px;
  right: 4px;
  height: calc(30px/2);
  width: calc(151px/2);
  display: block;
  background: #fff;
}

.tooltipClassName {
  padding: 0;
  overflow: hidden;
}

.reviewQuote {
  background: #f5f4fa;
  border-radius: 4px;
  padding: 8px 12px;
  color: #605377;
  font-size: 11px;
  margin: 0 13px;
  display: flex;
  flex-direction: column;
  gap: 2px;

  .twitterRating {
    font-size: 10px;

    svg {
      height: 14px;
      width: auto;
    }
  }
}

.reviewQuoteContent {
  display: flex;
  flex-direction: row;
  gap: 7px;
}

.reviewQuoteLine {
  width: 4px;
  background: #E5DFF0;
  border-radius: 1000em;
  flex-shrink: 0;
}
