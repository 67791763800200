.brandSource {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.brandSourceIcon {
  background: #fff;
  border: 1px solid #efefef;
  border-radius: calc(var(--icon-size, 32px)/3);
  width: var(--icon-size, 32px);
  height: var(--icon-size, 32px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  svg {
    display: block;
    width: var(--icon-scale, 75%);
    height: var(--icon-scale, 75%);
  }
}


.brandSourceIcon + .brandSourceName {
  margin-left: 0.5em;
}

.brandSourceName {
  display: block;
  color: var(--text);
  font-weight: 500;
}