.container {
  position: relative;
  width: auto;
  align-self: flex-start;
  border-radius: 100em;
  --size: 34px;
}

.edit {
  position: absolute;
  z-index: 1;
  inset: 14% 16% auto auto;
  background: hsl(0, 0%, 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: var(--size);
  height: var(--size);
  transform: translate(50%, -50%);

  svg {
    width: 50%;
    height: 50%;
    color: hsl(0, 0%, 44%);
    display: block;
  }

  &:hover {
    background: hsl(0, 0%, 98%);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.42);
  }

  input {
    display: none;
  }
}
