.searchInput {
  margin: 6px -12px;
}

.navigationBottom {
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
}

.brandSources {
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(auto-fill, 200px);
}

.brandSource {
  cursor: pointer;
  border-radius: 0.5em;
  padding: 0.5em;

  &:not(.premium):hover {
    background: var(--primary-color);
    --text: #fff;
  }
}