.container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  box-sizing: border-box;
  z-index: 1;
  width: 360px;
  max-height: 300px;
  transition: 200ms;
  overflow: hidden;
  position: relative;
}

.loading .input {
  opacity: 0;
}

.content {
  padding: 4px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.input {
  box-sizing: border-box;
  margin: 0;
  padding: 8px;
  border: 0;
  outline: 0;
  width: 100%;
  transition: 200ms;
  border-bottom: 1px solid hsl(216, 8%, 88%);
}

.input:first-child {
  margin-top: 0;
}

.row {
  background: none;
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 0;

  &:hover {
    background: hsl(0, 0%, 94%);
  }
}
