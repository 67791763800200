.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: #475467;
  font-style: normal;
  font-weight: 400;
  flex-grow: 1;
  font-size: 14px;
  line-height: 1.5em;
  --size: 20px;

  &.small {
    --size: 14px;
    font-size: 12px;
    line-height: 1.5em;
  }
}

.icon {
  color: hsl(220, 13%, 46%);
  min-width: var(--size);
  height: var(--size);
  display: block;

  svg, img {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: contain;
  }
}

.divider {
  height: 1px;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  background-color: var(--border-color, #efefef);
  border-radius: 50%;
}
