.nan {
  font-size: 10px;
  color: rgb(75, 75, 75);
}



.circle {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #fff;
  border: 2px solid rgba(0, 0, 0, 0.07);
  flex-shrink: 0;
  background: var(--circle);

  svg {
    height: 0.6em;
    width: 0.6em;
  }
}

.tooltipSentimentScore {
  font-weight: 600;
  color: #333;
  font-size: 12px;
  white-space: nowrap;
}

.tooltipSentimentText {
  margin-top: 0.25em;
  color: #333;
  font-size: 12px;
  white-space: nowrap;
}

.tooltipSentimentWords {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border-top: 1px solid #c5c5c5;
  color: rgb(163, 163, 163);
  display: flex;
  flex-direction: row;
  margin: 1em -0.2em -0.2em -0.2em;
  white-space: nowrap;
  font-size: 12px;
}

.tooltipSentimentValue {
  font-weight: 900;
}

.tooltipSentimentWord {
  margin: 0.2em;
  white-space: nowrap;
}
