.box {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  border: 1px solid rgba(0,0,0,.06);
  color: rgba(0,0,0,.56);
}

.nan {
  font-size: 10px;
  color: rgb(75, 75, 75);
  background: hsl(0, 0%, 90%);
}