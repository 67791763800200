.container {
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 24px 0 0 0;
  overflow: hidden;
  white-space: pre;
  --icon-size: 32px;
}

.button {
  display: flex;
  flex-direction: row;
  padding: 8px;
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 1;
  border-radius: 4px;
  transition: 200ms;
  gap: 12px;
  cursor: pointer;
  text-decoration: none;
  position: relative;

  &:hover {
    background: hsl(220, 20%, 97%);
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 1;
  width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.name {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: hsl(220, 24%, 27%);
}

.teamName {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: hsl(220, 18%, 34%);
}

.teamLogo {
  position: absolute;
  bottom: 4px;
  left: 4px;
}