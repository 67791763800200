.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container > * {
  z-index: 2;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  z-index: 1;
}

.content {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0.5em;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 2;
}

.header {
  padding: 0.5em 1em;
  background: var(--error);
  color: #fff;
  font-size: 1.5em;
  font-weight: 600;
}

.deleteButton {
  margin: 0.5em 1em 1em 1em;
  background: var(--error-input);
  color: var(--error-text);
  border: 1px solid var(--error);
  border-radius: 0.25em;
  padding: 0.5em 1em;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  transition: 200ms;
}

.deleteButton:hover {
  box-shadow: 0 2px 4px var(--error-border);
  background: var(--error);
  color: #fff;
}


.deleteButton.disabled {
  background: var(--accent-disabled);
  color: var(--accent-text);
  border-color: var(--accent-disabled);
  cursor: initial;
}

.deleteButton.disabled:hover {
  box-shadow: none;
}

.input {
  display: block;
  outline: 0;
  font-size: 1em;
  padding: 0.5em;
  border: 1px solid var(--text);
  background: #fff;
  border-radius: 0.5em;
  margin: 0.5em 1em;
}

.text {
  margin: 0.5em 1em 0 1em;
  line-height: 1.5em;
}