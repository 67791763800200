.variables {
  --border-radius: 8px;
  --icon-size: 20px;
  --font-size: 16px;
  --line-height: calc(1.5 * var(--font-size));
  --input-padding-vertical: 8px;
  --input-padding-horizontal: 12px;
  --icon-padding-vertical: 10px;
  --icon-padding-horizontal: 12px;
  --label-gap: calc(var(--icon-padding-horizontal) * 2 / 3);
  --box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --background-color: hsl(0, 0%, 100%);
  --border-color: hsl(217, 16%, 84%);
  --icon-color: hsl(0, 0%, 47%);
  --title-color: hsl(218, 24%, 27%);
  --checkbox-color-hsl: 218, 63%, 59%;
  --checkbox-color: hsl(var(--checkbox-color-hsl));
  --error-color: hsl(0, 57%, 46%);

  &.small {
    --border-radius: 5px;
    --icon-size: 16px;
    --font-size: 12px;
    --line-height: 17px;
    --input-padding-vertical: 5px;
    --icon-padding-vertical: 6px;
    --icon-padding-horizontal: 7px;
  }
}
.container {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 5px;
  font-family: var(--main-font);

  &.withSubmit .label {
    padding-bottom: 20px;
  }
}

.title {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--title-color);
}
.subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: hsl(216, 18%, 34%);
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--label-gap);
  border: 1px solid var(--border-color);
  box-shadow: var(--box-shadow);
  background: var(--background-color);
  border-radius: var(--border-radius);

  & > :first-child {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }

  & > :last-child {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
}

.input,
.textarea {
  padding: var(--input-padding-vertical) var(--input-padding-horizontal);
  flex-grow: 1;
  outline: 0;
  border: none;
  border-color: transparent;
  font-size: var(--font-size);
  line-height: var(--line-height);
  overflow: hidden;
  resize: none;
  min-height: calc(var(--line-height) + 2 * var(--input-padding-vertical));
  font-family: var(--main-font);
  position: relative;

  &[type='color'] {
    cursor: pointer;
    -webkit-appearance: none;
    padding: 0;
    border-radius: calc(var(--border-radius) - 1px);

    &::-webkit-color-swatch-wrapper {
      padding: 0;
    }

    &::-webkit-color-swatch {
      border: none;
    }
  }
  &[type='checkbox'],
  &[type='radio'] {
    display: none;
    & + .icon {
      --stroke-width: 2px;
    }
    &:not(:checked) + .icon svg {
      opacity: 0;
    }
    &:not(:checked) + .icon .circle {
      opacity: 0;
    }
  }
}


.error {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: var(--error-color);
}

.input {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.disabled {
  .input {
    cursor: default;
    background: hsla(0, 0%, 94%, 0.3);
    color: hsl(0, 0%, 63%);
  }
}

.icon {
  width: var(--icon-size);
  height: var(--icon-size);
  border-radius: 50%;
  display: block;
  color: var(--icon-color);
  flex-shrink: 0;
  flex-grow: 0;
  margin: var(--icon-padding-vertical) 0 var(--icon-padding-vertical) var(--icon-padding-horizontal);
  transition: 200ms;

  & + .input {
    padding-left: 0;
  }

  svg,img {
    height: 100%;
    width: 100%;
    display: block;
  }
}

.circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--checkbox-color);
}

.checkbox .icon {
  background: hsla(var(--checkbox-color-hsl), 0.12);
  color: var(--checkbox-color);
  --stroke-width: 2.2;
  padding: 1px;
  border: 1px solid var(--checkbox-color);
  border-radius: 4px;

  svg {
    transition: opacity 200ms;
  }
}
.radio .icon {
  background: hsla(var(--checkbox-color-hsl), 0.12);
  color: var(--checkbox-color);
  --stroke-width: 2.2;
  padding: 3px;
  border: 1px solid var(--checkbox-color);
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    transition: opacity 200ms;
  }
}

.submit {
  position: absolute;
  bottom: 1px;
  right: 1px;
}

.loading .label {
  opacity: 1;
  opacity: 1;
  background: linear-gradient(
    110deg,
    hsl(220, 40%, 98%) 8%,
    hsl(0, 0%, 100%) 18%,
    hsl(220, 40%, 98%) 33%
  );
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}
.loading + .loading .label {
  opacity: 0.6;
}
.loading + .loading + .loading .label {
  opacity: 0.2;
}

.select {
  width: 100%;
  position: relative;
  .chevron {
    transition: 0.2s ease-out all;
    color: #c6c6c6;
  }
  input {
    width: 100%;
  }
  &.focusedSelect {
    .chevron {
      transform: rotate(180deg);
    }
  }
}
.options {
  max-height: 200px;
  overflow-y: auto;
  background: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 9px 2px #b9b9b9ad;
  border-radius: 5px;
  z-index: 10;
  border-radius: var(--border-radius);
}
.option {
  border: none;
  transition: all 0.3s ease-out;
  font-style: normal;
  font-weight: 400;
  background-color: var(--background-color);
  color: var(--title-color);
  font-size: var(--font-size);
  line-height: var(--line-height);
  padding: var(--input-padding-vertical) var(--input-padding-horizontal);
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;

  &.currentOption {
    background: hsla(var(--checkbox-color-hsl), 0.3);
  }
  &.selectedOption {
    background-color: var(--checkbox-color);
    color: white;
  }
}
.optionIcon {
  width: 16px;
  height: 16px;
  display: block;
  svg, img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.container :global {
  .quill {
    flex-grow: 1;
    outline: 0;
    border: 0;
    overflow: visible;
    resize: none;
    min-height: calc(var(--line-height) + 2 * var(--input-padding-vertical));
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
  }
  .ql-toolbar {
    &.ql-snow {
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
    }
  }
  .ql-container {
    font-size: var(--font-size);
    font-family: var(--main-font);
    &.ql-snow {
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }
  }
  .ql-editor {
    padding: var(--input-padding-vertical) var(--input-padding-horizontal);
    line-height: 1.5em;

    &:before {
      /* this is how quill does placeholder*/
      left: var(--input-padding-horizontal);
      font-style: normal;
    }

    p,
    ul li,
    ol li {
      font-size: var(--font-size);
      font-family: var(--main-font);
    }
    h1 {
      font-size: 2em;
      line-height: 1.5em;
    }
    h2 {
      font-size: 1.6em;
      line-height: 1.5em;
    }
    h3 {
      font-size: 1.5em;
      line-height: 1.5em;
    }
    h4 {
      font-size: 1.3em;
      line-height: 1.5em;
    }
    h5 {
      font-size: 1.1em;
      line-height: 1.5em;
    }
    h6 {
      font-size: 1em;
      line-height: 1.5em;
    }
    ul,
    ol {
      padding-left: 0.2em;
    }
  }
}
