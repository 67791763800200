.container {
  background: #4E1F8E;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 14px 20px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.44px;
  color: #fff;
  text-align: center;
}

.subHeader {
  font-size: 12px;
  font-weight: 200;
  letter-spacing: -0.38px;
  color: #fff;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
}

.spacer {
  flex-grow: 1000;
}

.closeButton {
  padding: 12px;
  margin-right: -14px;
  transition: 200ms;
  cursor: pointer;
}

.closeButton:hover {
  transform: scale(1.4);
}