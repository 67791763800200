.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.chevron {
  width: 16px;
  height: 16px;
  display: block;
  color: hwb(220 82% 13%);

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.crumb {
  color: hsl(220, 18%, 34%);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  transition: 200ms;

  &.link {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }


  svg {
    width: 16px;
    height: 16px;
    display: block;
  }
}
