.containerWrap {
  background: var(--primary-w90-color);
  min-height: 100vh;
  overflow: auto;
}

.container {
  padding: 1em;
  flex-basis: 0;
  flex-grow: 1;

  padding-top: var(--header-height);
  margin-left: var(--nav-width);
  transition: 200ms;
}