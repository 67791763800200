.icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  overflow: hidden;
  width: var(--icon-size);
  height: var(--icon-size);
  flex-shrink: 0;
  position: relative;
  color: hsl(220, 20%, 44%);
  font-size: calc(var(--icon-size) / 3);
  font-weight: 500;
  overflow: hidden;

  &.roundedSquare {
    border-radius: calc(var(--icon-size) / 8.75);
    background: hsl(220, 20%, 94%);
  }

  &.circle {
    border-radius: 100em;
    background: hsl(220, 20%, 94%);
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
