.container {
  color: #333;
  font-size: 12px;
}

.header {
  font-weight: bold;
  color: #5B5B5B;
  padding: 0.25em 0;
  font-size: 15px;
}

.text {
  width: 22em;
  color: #929292;
  white-space: normal;
  overflow: hidden;
  padding-bottom: 0.25em;
  font-size: 12px;
}