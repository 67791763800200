.container {
  margin: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;

  h1 {
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.02em;
    font-size: 24px;
    color: hsl(220, 43%, 11%);
    margin: 0;
    padding: 0;
    flex-shrink: 1;
    flex-grow: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  flex-grow: 1;
  flex-shrink: 1;
  gap: 13px;
}

.icon {
  width: 24px;
  height: 24px;
  display: block;
  flex-shrink: 0;
  color: hsl(220, 13%, 46%);

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}
