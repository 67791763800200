.suggestConcept {
  padding: 10px;
  border-radius: 6px;
  position: relative;
  background: #fff;
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.conceptName {
  color: #444;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > span {
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.suggestConceptWords {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.5em -4px;
}

.suggestConceptWordsTitle {
  font-size: 11px;
  letter-spacing: 0.03em;
  color: #444;
  width: 100%;
}

.suggestConceptWord {
  margin: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 300;
  padding: 2px 7px;
}

.suggestConceptCreate, .activeConceptCreate {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #ddd;
  transition: 200ms;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.suggestConceptCreate:hover {
  background: rgba(82, 79, 159, 0.2);
}

.suggestConceptCreateButton, .activeConceptCreateButton {
  background: #eee;
  border-radius: 6px;
  padding: 6px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  pointer-events: none;
  opacity: 0;
  transition: 200ms;
  font-size: 13px;
}

.suggestConceptCreate:hover .suggestConceptCreateButton {
  opacity: 1;
}

.activeConceptCreate {
  background: rgba(82, 79, 159, 0.2);
  &:hover {
    background: rgba(82, 79, 159, 0.4);
  }
}

.activeConceptCreateButton {
  opacity: 1;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttons {
  display: flex;
  flex-direction: row;
}