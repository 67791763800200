.section {
  border-top: 1px solid #eee;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
}

.sectionName {
  display: flex;
  flex-direction: row;
  font-size: 11px;
  text-transform: uppercase;
  padding: 8px 6px;
  letter-spacing: 0.03em;
  justify-content: space-between;
  transition: 200ms;
  cursor: pointer;

  &:hover {
    background: #efefef;
  }

  svg {
    flex-shrink: 0;
    transition: 300ms;
    transform: rotate(180deg);
    height: 4px;
    width: auto;
    align-self: center;
  }
}

.opened .sectionName svg {
  transform: rotate(0deg);
}

.label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 13px;
  font-weight: 380;
  gap: 4px;
  padding: 3px 0;
  input {
    flex-shrink: 0;
  }

  &:hover{
    color: purple;
    cursor: pointer;
  }
}