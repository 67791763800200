.table {
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: 1fr min-content min-content;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  background: hsl(0, 0%, 100%);

  &.bordered {
    border-radius: 10px;
    border: 1px solid var(--border-color);
    overflow: auto;

    &.small {
      box-shadow: none;
    }
  }
}

.cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 20px 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: hsl(220, 43%, 11%);
  border-top: 1px solid var(--border-color);
  transition: 200ms;
  text-decoration: none;

  .small & {
    padding: 10px 12px;
  }

  &.hoverable {
    cursor: pointer;
  }

  &.sticky {
    position: sticky;
    left: 0;
    background: hsl(0, 0%, 100%);
    border-right: 1px solid var(--border-color);
  }

  &.active {
    background: hsl(220, 20%, 98%);
  }

  &.first {
    border-top: none;
  }

  &.hoverable:hover , &.hoverable.hover {
    background-color: hsla(221, 66%, 42%, 0.05);

    color: hsl(221, 66%, 42%);
  }

  &.right {
    justify-content: flex-end;
  }
  &.center {
    justify-content: center;
  }
  &.yellow {
    background: hsl(60, 67%, 96%);
  }

  /* HACK TODO: remove*/
  a {
    text-decoration: none;
    color: hsl(220, 43%, 11%);
    &:hover {
      text-decoration: underline;
    }
  }
}

.cellHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
  gap: 12px;
  background: hsl(210, 20%, 98%);
  color: hsl(210, 18%, 34%);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-bottom: 1px solid var(--border-color);
  transition: 200ms;
  top: 0;
  position: sticky;
  z-index: 1;

  .small & {
    padding: 10px 12px;
  }

  &.hoverable {
    cursor: pointer;

    &:hover .chevron {
      background: hsl(220, 20%, 93%);
    }
  }

  &.sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    border-right: 1px solid var(--border-color);
  }

  svg {
    transition: 200ms;
  }

  &.right {
    justify-content: flex-end;
  }

  .bordered &:first-child {
    border-top-left-radius: 10px;
  }
}

.bordered {
  .cellHeader:not(:has(+ .cellHeader)) {
    border-top-right-radius: 10px;
  }
}

.chevron {
  height: 16px;
  width: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 500ms;

  svg {
    width: 85%;
    height: 85%;
    display: block;
  }
  &.asc,
  &.desc {
    background: hsl(220, 20%, 90%);
  }

  &.asc svg {
    transform: rotate(-90deg);
  }

  &.desc svg {
    transform: rotate(90deg);
  }
}
